//
//
//
//
//
//
//

import LoadingScreen from "@/views/LoadingScreen";
import { mapState, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    LoadingScreen
  },
  computed: {
    ...mapState({
      substrateApi: state => state.substrate.api,
      isLoadingSubstrateApi: state => state.substrate.isLoadingApi
    })
  },
  data: () => ({
    address: ""
  }),
  methods: {
    ...mapActions({
      initWeb3: "metamask/initWeb3"
    })
  }
};
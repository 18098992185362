import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import { toFormatDebioCoin } from "@/lib/polkadotProvider/index";
import localStorage from "@/lib/local-storage";
import store from "@/store";
const defaultHandler = {
  get: function (target, name) {
    return Object.prototype.hasOwnProperty.call(target, name) ? target[name] : (dataEvent, value, valueMessage) => {
      const data = dataEvent[0];
      const id = data[value];
      const params = {
        number: id
      };
      const wording = "for (" + data[valueMessage].substr(0, 4) + "..." + data[valueMessage].substr(data[valueMessage].length - 4) + ")";
      return {
        data,
        id,
        params,
        wording
      };
    };
  }
};
const handler = {
  labs: async (dataEvent, value, valueMessage) => {
    const data = dataEvent[0];
    const id = data[value];
    const params = {
      notifId: id
    };
    let wording = "".concat(valueMessage, " ").concat(data.verificationStatus.toLowerCase());
    const notifications = JSON.parse(localStorage.getLocalStorageByName("LOCAL_NOTIFICATION_BY_ADDRESS_".concat(localStorage.getAddress(), "_lab")));
    if ((data === null || data === void 0 ? void 0 : data.verificationStatus) === "Verified") {
      wording = "Congrats! ".concat(wording);
    }
    store.dispatch("substrate/getLabAccount");
    const isExist = notifications === null || notifications === void 0 ? void 0 : notifications.find(notif => {
      var _notif$params, _notif$data;
      return (notif === null || notif === void 0 ? void 0 : (_notif$params = notif.params) === null || _notif$params === void 0 ? void 0 : _notif$params.notifId) === id && (notif === null || notif === void 0 ? void 0 : (_notif$data = notif.data) === null || _notif$data === void 0 ? void 0 : _notif$data.verificationStatus) === (data === null || data === void 0 ? void 0 : data.verificationStatus);
    });
    if (isExist) return;
    return {
      data,
      id,
      params,
      wording
    };
  },
  balances: async (dataEvent, value, valueMessage) => {
    const web3 = store.getters["metamask/getWeb3"];
    const data = dataEvent;
    const id = data[value];
    const params = {
      number: id
    };
    const finalText = await toFormatDebioCoin(data[valueMessage]);
    const wording = web3.utils.fromWei(finalText, "ether") + " DBIO!";
    return {
      data,
      id,
      params,
      wording
    };
  },
  orders: async (dataEvent, value, valueMessage, event) => {
    const web3 = store.getters["metamask/getWeb3"];
    const data = dataEvent[0];
    const id = data[value];
    const params = {
      orderId: id
    };
    const formatedHash = "".concat(id.substr(0, 4), "...").concat(id.substr(id.length - 4));
    const notifications = JSON.parse(localStorage.getLocalStorageByName("LOCAL_NOTIFICATION_BY_ADDRESS_".concat(localStorage.getAddress(), "_lab")));
    let wording = "".concat(valueMessage(formatedHash));
    if (event.method === "OrderRefunded" || event.method === "OrderFulfilled") {
      var _data$additionalPrice, _data$prices$;
      const qcPrice = data === null || data === void 0 ? void 0 : (_data$additionalPrice = data.additionalPrices[0]) === null || _data$additionalPrice === void 0 ? void 0 : _data$additionalPrice.value.replaceAll(",", "");
      const testingPrice = data === null || data === void 0 ? void 0 : (_data$prices$ = data.prices[0]) === null || _data$prices$ === void 0 ? void 0 : _data$prices$.value.replaceAll(",", "");
      const coin = event.method === "OrderRefunded" ? qcPrice : testingPrice;
      const currency = data.orderFlow === "StakingRequestService" ? "DBIO" : data === null || data === void 0 ? void 0 : data.currency;
      wording = "".concat(valueMessage(web3.utils.fromWei(coin) + currency, formatedHash));
      if (event.method === "OrderFulfilled" && data.orderFlow === "StakingRequestService") {
        wording = "".concat(valueMessage(web3.utils.fromWei(coin) + currency, formatedHash));
      }
    }
    const isExist = notifications === null || notifications === void 0 ? void 0 : notifications.find(notif => {
      var _notif$params2;
      return ((_notif$params2 = notif.params) === null || _notif$params2 === void 0 ? void 0 : _notif$params2.orderId) === id && notif.data.status === data.status;
    });
    if (isExist) return;
    return {
      data,
      id,
      params,
      wording
    };
  },
  rewards: async (dataEvent, value, valueMessage) => {
    const web3 = store.getters["metamask/getWeb3"];
    const data = dataEvent;
    const id = data[value];
    const params = null;
    const finalText = await toFormatDebioCoin(data[valueMessage]);
    const coin = web3.utils.fromWei(finalText, "ether");
    const wording = "".concat(coin, " DBIO from ").concat(Number(coin) === 2 ? "account verification" : "wallet binding");
    return {
      data,
      id,
      params,
      wording
    };
  },
  geneticTesting: async (dataEvent, value, valueMessage) => {
    var _data$prices$2;
    const web3 = store.getters["metamask/getWeb3"];
    const data = dataEvent[0];
    const id = data[value];
    const params = {
      orderId: id
    };
    const formatedHash = "".concat(id.substr(0, 4), "...").concat(id.substr(id.length - 4));
    const coin = data === null || data === void 0 ? void 0 : (_data$prices$2 = data.prices[0]) === null || _data$prices$2 === void 0 ? void 0 : _data$prices$2.value.replaceAll(",", "");
    const wording = "".concat(valueMessage(web3.utils.fromWei(coin) + (data === null || data === void 0 ? void 0 : data.currency), formatedHash));
    return {
      data,
      id,
      params,
      wording
    };
  },
  services: async (dataEvent, value, valueMessage) => {
    const data = dataEvent[0];
    const id = data[value];
    const params = {
      id: id
    };
    const formatedHash = "".concat(id.substr(0, 4), "...").concat(id.substr(id.length - 4));
    const wording = "".concat(valueMessage, " ").concat(formatedHash);
    const notifications = JSON.parse(localStorage.getLocalStorageByName("LOCAL_NOTIFICATION_BY_ADDRESS_".concat(localStorage.getAddress(), "_lab")));
    const isExist = notifications === null || notifications === void 0 ? void 0 : notifications.find(notif => {
      var _notif$params3;
      return ((_notif$params3 = notif.params) === null || _notif$params3 === void 0 ? void 0 : _notif$params3.id) === id;
    });
    if (isExist) return;
    return {
      data,
      id,
      params,
      wording
    };
  }
};

// If property not found, return defaultHandler
export const labHandler = new Proxy(handler, defaultHandler);
import apiClientRequest from "@/lib/api";
export const getOrdersData = async function (hash, page, size) {
  let keyword = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
  const {
    data
  } = await apiClientRequest.get("substrate/orders/list/lab/".concat(hash), {
    params: {
      keyword,
      size,
      page
    }
  });
  return data;
};